/*

Sunburst-like style (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
  display: block;
  overflow-x: auto;
  /* padding: 0.5em; */
  font-size: 12px;
  background: transparent;
  /* color: #bababa; */
}

.hljs-comment, .hljs-quote {
  color: #aeaeae;
  font-style: italic;
}

.hljs-keyword, .hljs-selector-tag, .hljs-type {
  color: #e28964;
}

.hljs-string {
  color: #65b042;
}

.hljs-subst {
  color: #daefa3;
}

.hljs-regexp, .hljs-link {
  color: #e9c062;
}

.hljs-title, .hljs-section, .hljs-tag, .hljs-name {
  color: #89bdff;
}

.hljs-class .hljs-title, .hljs-doctag {
  text-decoration: underline;
}

.hljs-symbol, .hljs-bullet, .hljs-number {
  color: #3387cc;
}

.hljs-params, .hljs-variable, .hljs-template-variable {
  color: #3e87e3;
}

.hljs-attribute {
  color: #cda869;
}

.hljs-meta {
  color: #8996a8;
}

.hljs-formula {
  background-color: #0e2231;
  color: #f8f8f8;
  font-style: italic;
}

.hljs-addition {
  background-color: #253b22;
  color: #f8f8f8;
}

.hljs-deletion {
  background-color: #420e09;
  color: #f8f8f8;
}

.hljs-selector-class {
  color: #9b703f;
}

.hljs-selector-id {
  color: #8b98ab;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}