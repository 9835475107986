input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

/* #region highcharts ovveride */

.highcharts-background {
    fill: transparent
}

/* #endregion highcharts ovveride */

/* #region: scroll override */

* {
    scrollbar-color: #373e4f transparent;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
    background-color: #373e4f;
    border: 4px solid transparent;
    border-radius: 7px;
    box-shadow: inset 0 0 0 10px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #5dbb46;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: none;
}

/* #endregion: scroll override */

/* #region: Fabric UI override */

.ms-Grid {
    margin: 12px;
}

.ms-DetailsHeader {
    padding-top: 0;
}

.ms-Grid-col {
    padding: 8px;
}

.ms-Button--commandBar {
    background-color: #1e273b;
}

.ms-Dropdown-optionText {
    color: white;
}

.is-disabled .ms-TextField-fieldGroup {
    border-color: rgb(60, 64, 74);
}

.ms-Nav-chevronButton::after {
    border-left: unset !important;
}

.ms-Nav-compositeLink .ms-Nav-chevronButton {
    border: unset
}

/* #endregion: Fabric UI override */

.nav-menu {
    margin: 0;
    padding: 0;
    width: 200px;
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 299;
    background-color: #1e273b;
}

.nav-content {
    margin-left: 200px;
}

.nav-content.menuHidden {
    margin-left: 0;
}

.nav-menu.menuHidden {
    display: none;
}

.nav-background {
    background-color: rgba(0, 0, 0, 0.2);
    filter: blur(25px);
    z-index: 200;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.nav-hide {
    display: initial;
}

.nav-hide.isHidden {
    display: none;
}

.nav-show {
    display: none;
}

.nav-show.isShown {
    display: initial;
}

@media only screen and (max-width: 800px) {
    .nav-content {
        margin-left: 0;
    }
    .nav-menu {
        display: none;
    }
    .nav-hide {
        display: none;
    }
    .nav-show {
        display: initial;
    }
    .nav-menu.isShown {
        display: block !important;
    }
    .nav-background.isShown {
        display: unset;
    }
}

.opacity_blink {
    animation: opacity_blinker 2s linear infinite;
}

@keyframes opacity_blinker {
    50% {
        opacity: 0.3;
    }
}

.StickyFirstColumnRowTable {
    color: white;
    font-size: 12px;
    /* border-collapse: separate; */
    border-spacing: 0;
}

.StickyFirstColumnRowTable tr {
    /* line-height: 20px; */
}

.StickyFirstColumnRowTable th, .StickyFirstColumnRowTable td {
    padding: 4px;
    text-align: left;
    white-space: nowrap;
}

.StickyFirstColumnRowTable td {
    border-left: 1px solid #2e3649;
    border-top: 1px solid #2e3649;
}

.StickyFirstColumnRowTable thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #1b2337;
    border-bottom: 1px solid #2e3649;
    font-size: 14px;
}

.StickyFirstColumnRowTable thead th+th {
    border-left: 1px solid #2e3649;
}

.StickyFirstColumnRowTable tbody th {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: #1b2337;
}

.StickyFirstColumnRowTable thead th:first-child {
    left: 0;
    z-index: 1;
    border-right: 1px solid #2e3649;
}

.StickyFirstColumnRowTable tbody th {
    border: 1px solid #2e3649;
    border-width: 1px 1px 0 0;
}

.CustomTable {
    color: white;
    font-size: 14px;
    /* border-collapse: separate; */
    border-spacing: 0;
    width: 100%;
}

.CustomTable th, .CustomTable td {
    padding: 4px;
    text-align: left;
}

.CustomTable td {
    border-top: 1px solid #2e3649;
}

.CustomTable tbody tr:nth-child(odd) {
    background: rgba(30, 39, 59, 0.2);
}

hr.centerAlignedHr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

hr.rightAlignedHr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.15));
}

hr.leftAlignedHr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

.number {
    color: white;
    text-align: center;
    font-size: 2em;
}

.number span {
    font-weight: 300;
    font-size: 0.8em;
}

.counterContainer {
    text-align: center;
    /* margin: -6px; */
    margin-top: -6px;
    padding: 6px;
    /* border: 1px solid rgba(255, 255, 255, 0.03); */
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.03), transparent 25%, transparent 75%, rgba(255, 255, 255, 0.03));
}

.counterValue {
    color: white;
    text-align: center;
    font-size: 1.8em;
}

.counterValue span {
    font-weight: 300;
    font-size: 0.8em;
}

.counterLabel {
    font-size: 1.3em;
    font-variant: small-caps;
    font-feature-settings: normal;
    margin-top: -4px;
}

.description {
    color: white;
    /* padding: 10px; */
    /* line-height: 40px; */
    font-weight: bold;
    text-align: center;
}

.container {
    border: 1px solid rgba(255, 255, 255, 0.03);
    border-radius: 0 30px 0 30px;
    padding: 6px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.03), transparent 25%, transparent 75%, rgba(255, 255, 255, 0.03));
}

.noSelect * {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.noWrap {
    white-space: nowrap;
}

pre {
    margin: 0;
}

.highcharts-button-box {
    stroke: transparent;
    fill: #414859;
}

.highcharts-button.highcharts-reset-zoom text tspan {
    fill: white;
}

.highcharts-root{
    font-family: 'Ubuntu', sans-serif !important;
}