.cardHeaderContainer {
    overflow: hidden;
}

.cardHeaderLeft {
    float: left;
}

.cardHeaderRight {
    text-align: right;
    overflow: hidden;
}

@media screen and (max-width: 400px) {
    .cardHeaderLeft {
        float: none;
    }
}