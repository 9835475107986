.htmlFieldContent {
    color: white;
    border: 1px solid rgb(60, 64, 74);
    border-radius: 2px;
    line-height: 30px;
    padding: 0 8px;
    font-size: 14px;
    /* max-height: 30px; */
}

.disabled {
    color: rgb(200, 200, 200);
}

.oneline {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}