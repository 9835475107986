body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --ngen-green: #5dbb46;
}

/* * {
  color: white;
  cursor: default;
} */

body {
  background-color: #1b2337;
}

